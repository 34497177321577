<template>
<div class="modal fade " :id="modalName" tabindex="-1" role="dialog" :aria-labelledby="modalName" aria-hidden="true">
    <div class="modal-dialog  modal-md modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header bg-primary">
                <h1 class="modal-title white">Change Refund Status</h1>
                <button @click="resetData" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i class="bx bx-x"></i>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <h4 class="text-dark ">Status</h4>
                            <div class="form-group">
                                <div class="controls">
                                    <VueMultiselect v-model="selectedRefundStatus" class="multiselect-blue" :options="refundStatusOptions" :close-on-select="true" :allow-empty="false" label="name" track-by="value" :show-labels="false" />
                                    <div class="text-danger" v-if="errors.status">{{ errors.status }}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="selectedRefundStatus?.name === 'Processing' &&  (refund?.payment_method !== 'Cash')">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Refund By payment gateway</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="refundByPaymentGateway" type="checkbox" class="custom-control-input" id="refundPaymentGateway">
                                                <label class="custom-control-label mr-1" for="refundPaymentGateway"></label>
                                            </div>
                                            <!-- <span class="font-medium-1">{{ refundByPaymentGateway ? "Yes" : "No" }}</span> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Add Note</label>
                                    <div style="margin-top: .5rem">
                                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                                            <div>
                                                <input v-model="have_note" type="checkbox" class="custom-control-input" id="haveAppointmentNote">
                                                <label class="custom-control-label mr-1" for="haveAppointmentNote"></label>
                                            </div>
                                            <span class="font-medium-1">{{ have_note ? "Yes" : "No" }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="have_note">
                        <div class="col-12 ">
                            <div class="form-group">
                                <div class="controls">
                                    <label>Reason</label>
                                    <VueMultiselect :allowEmpty="false" v-model="selectedReason" class="multiselect-blue" :options="reasonOptions" :close-on-select="true" placeholder="Select reason" label="name" track-by="value" :show-labels="false" />
                                </div>

                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <label>Note</label>
                                <textarea v-model="note_description" class="form-control" id="" rows="8"></textarea>
                            </div>
                        </div>
                        <div class="col-12" v-if="currentUserTypeOptions !== undefined && currentUserTypeOptions.length > 2">
                            <label>User Type</label>
                            <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false" :options="currentUserTypeOptions" :close-on-select="true" placeholder="Select Type" label="name" track-by="value" :show-labels="false" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer border-0 pt-0">
                <button :disabled="isSaveButtonDisable" @click="updateRefundStatusHandler" type="button" class="btn btn-primary">
                    <i class="bx bx-x d-block d-sm-none"></i>
                    <span class="d-none d-sm-block">Save </span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";

export default {
    name: "RefundStatusChangeModal",
    props: {
        modalName: {
            type: String,
            required: true,
            default: 'refundStatusChangeModal'
        },
        refundId: {
            type: [Number, String],
            required: true,
        },
        refundStatus: {
            type: String,
            required: true
        },
        selectedRefund: {
            type: Object,
            required: true
        },
        getRefundList: {
            type: Function,
            required: true
        }
    },
    emits: [''],
    mixins: [Loader, ShowToastMessage],
    data() {
        return {
            refund: {},
            have_note: false,
            refundByPaymentGateway: false,
            selectedRefundStatus: {
                name: 'None',
                value: ''
            },
            selectedReason:{value: '', name: 'None'},

            order: {},
            status: null,
            initialStatusData: {
                value: '',
                name: ''
            },
            putStatusData: {},
            errors: {
                status: ''
            },
            selectedUserType: {value: '', name: 'None'},
            note_description: '',
            isOrderStatusChange: false,

        }
    },
    computed: {
        ...mapGetters({
            previousRoute: 'previousRoute',
            userType: 'appUsers/userType',
        }),
        refundStatusOptions() {
            const refundStatusObj = {
                0: 'Pending',
                1: 'Processing',
                2: 'Refunded',
                3: 'Declined',
                4: 'Cancelled'
            }
            let status = Object.entries(refundStatusObj).map(([value, name]) => {
                return {
                    value: value,
                    name: name
                };
            });
            return [...status];
        },
        reasonOptions() {
            const reasonObj = {
                0: 'Incorrect Amount Charged',
                1: 'Duplicate Charge',
                2: 'Tech Poor Service',
                3: 'No Service Provided',
                4: 'Agent Error',
                5: 'Cancelled Appointment',
                6: 'Cancelled Product Order',
                7: 'Cancelled Repair Service',
                8: 'Other'
            }
            let reason = Object.entries(reasonObj).map(([key, value]) => {
                return {
                    value: key,
                    name: value
                };
            });
            return [{value: '', name: 'None'}, ...reason];
        },
        currentUserTypeOptions ()
        {
            
            return [{value: '', name: 'None'}, ...this.userType];

        },
        isRefundStatusChanged() {
            return !((this.initialStatusData.value === this.putStatusData.value) &&
                (this.initialStatusData.name === this.putStatusData.name));
        },
        isSaveButtonDisable ()
        {
            return this.have_note && (!((this.selectedUserType?.name !== 'None') && this.note_description && (this.selectedReason?.name !== 'None')))
        }
    },
    watch: {
        refundByPaymentGateway(currentRefundByPaymentGateway) {
            if (currentRefundByPaymentGateway === true) {
                this.showToastMessage({
                    type: 'success',
                    message: 'Refund will done by payment gateway'
                });
            }
        },

        refundStatus(status) {
            if (status) {
                // this.selectedRefundStatus = this.refundStatusOptions.find(({
                //     name
                // }) => name.toLowerCase() === status.toLowerCase());
                // initial refund status

                this.putStatusData = {
                    ...this.initialStatusData
                };
            }
        },
        selectedRefund(currentRefund) {

            if (currentRefund) {
                this.have_note = false;
                this.refundByPaymentGateway = false;
                this.note_description = "";
                this.selectedReason ={value: '', name: 'None'};
              
                this.refund = currentRefund;
                this.selectedRefundStatus = this.refundStatusOptions.find(({
                    name
                }) => name.toLowerCase() === currentRefund.status.toLowerCase());

                this.initialStatusData = {
                    value: this.selectedRefundStatus.value,
                    name: this.selectedRefundStatus.name
                };
            }

        },
        selectedRefundStatus() {
            this.status = this.selectedRefundStatus.value;
            // after change  refund status

            this.putStatusData = {
                value: this.selectedRefundStatus.value,
                name: this.selectedRefundStatus.name
            }
        },
        userType (currentUserTypes)
        {
            if (currentUserTypes?.length === 1) {
            console.log(currentUserTypes);
               this.selectedUserType = currentUserTypes[0];
           } 
        }

    },
    methods: {
        ...mapActions({
            getSettings: 'appSettings/getSettings',
            putRefundStatus: 'appRefunds/putRefundOnList',
            createRefund: 'appAfterpayPaymentGateways/createRefund',
            refundCreate: 'appPaymentGateways/refundCreate',
            postRefundNote: 'appRefundNotes/postRefundNote',
            getUserType: 'appUsers/getUserType',
        }),

        async updateRefundStatusHandler() {
            if (this.isRefundStatusChanged === false) {
                this.errors.status = 'Please Change the Status First'
                setTimeout(() => this.errors = {}, 4000);
                return;
            }

            if (this.have_note && (!(this.selectedUserType?.name && this.note_description && this.selectedReason?.name !== 'None'))) {
                this.showToastMessage({
                        type: 'error',
                        message: 'Please provide note related information'
                });
                return;
                  
            }

            if (this.refundByPaymentGateway && (this.refund ?.payment_method === "Payment Gateway") && (this.refund ?.payment_gateway === "Afterpay")) {
                //after pay refund api call
                const isAfterPayRefundSucceed = await this.afterPayPaymentGateWayRefundHander();
                console.log(isAfterPayRefundSucceed, "isAfterPayRefundSucceed");
                if (!isAfterPayRefundSucceed) {
                    this.showToastMessage({
                        type: 'error',
                        message: 'Afterpay Refund Failed.'
                    });
                    return;
                }
            }
            if (this.refundByPaymentGateway && (this.refund ?.payment_method === "Payment Gateway") && (this.refund ?.payment_gateway === "commbank simplify")) {
                //simplify  refund api call
                const isSimplifyRefundSucceed = await this.simplifyPaymentGateWayRefundHander();
                if (!isSimplifyRefundSucceed) {
                    this.showToastMessage({
                        type: 'error',
                        message: 'Simplify Refund Failed.'
                    });
                    return;
                }
            }
            const dataObj = {
                id: this.refund.id,
                data: {
                    status: (this.refundByPaymentGateway && ( (this.refund ?.payment_gateway === "Afterpay") ||  (this.refund?.payment_gateway === "commbank simplify"))) ? 2 : parseInt(this.status),
                    processed_by: this.$store.getters["appAuthLogin/authUser"].id,
                }
            }
            this.loader(true);
            await this.putRefundStatus(dataObj).then((response) => {
                this.loader(false);
                console.log(response);
                // error goes here
                if (response.message) {
                    this.showToastMessage(response);
                    return;
                }
                if (response.status === 200) {
                     this.have_note && this.createSingleRefundNote();
                    document.querySelector(`[data-target="#${this.modalName}"]`).click();
                    this.$emit('getRefundList');
                    this.showToastMessage({
                        type: 'success',
                        message: 'Refund status has updated.'
                    });
                }

            });

        },

        async afterPayPaymentGateWayRefundHander() {

            const dataObj = {
                id: (this.refund?.payment_gateway_id),
                data: {
                    requestId: (this.refund?.appointmentRefundsByReference?.uuid) ?? (this.refund?.orderRefundsByReference ?.uuid),
                    amount: {
                        amount: `${this.refund.amount/100}`,
                        currency: "AUD"
                    },
                    merchantReference: this.refund.reference,
                    refundMerchantReference: this.refund.reference,
                }

            }
            this.loader(true);
            return await this.createRefund(dataObj).then((response) => {
                this.loader(false);
                console.log(response);

                // error goes here
                if (response.message) {
                    this.showToastMessage(response);
                }
                return (response.status === 201);
            });

        },
        async simplifyPaymentGateWayRefundHander() {

            const dataObj = {
                payment_id: this.refund.payment_gateway_id,
                reason: this.refund.reason,
                reference: this.refund.reference,
                amount: this.refund.amount,
            }
            this.loader(true);
            return await this.refundCreate(dataObj).then((response) => {
                this.loader(false);
                console.log(response);

                // error goes here
                if (response.message) {
                    this.showToastMessage(response);
                }
                return response.status === 200 && (response?.data?.payment_status === "APPROVED");
            });

        },

         createSingleRefundNote() {

            const data = {
                user_id: this.$store.getters["appAuthLogin/authUser"].id,
                refund_id: this.refund.id,
                user_type: this.selectedUserType.value ?? '',
                type: this.selectedReason.value ?? '',
                description: this.note_description
            }

             this.postRefundNote(data).then((response) => {
                if (response.status === 201) {
                    return;
                }

                // this.errors.refundNote = {
                //     user_id: response ?.errors ?.user_id ?. [0] ?? '',
                //     refund_id: response ?.errors ?.refund_id ?. [0] ?? '',
                //     user_type: response ?.errors ?.user_type ?. [0] ?? '',
                //     type: response ?.errors ?.type ?. [0] ?? '',
                //     description: response ?.errors ?.description ?. [0] ?? '',
                // }

                if (response.message) {
                    this.showToastMessage(response);
                }
            });

        },

        async getCurrentUserTypes() {
            await this.getUserType(this.$store.getters["appAuthLogin/authUser"].id);
        },

        // resetData() {
        //     Object.assign(this.$data, this.$options.data.call(this));
        // }
    },
    async mounted() {
        await this.getCurrentUserTypes();
    },
}
</script>

<style scoped>

</style>
