<template>
  <div class="modal fade " id="refundViewModal" tabindex="-1" role="dialog" aria-labelledby="refundViewModal"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-xl modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header bg-primary">
          <h1 class="modal-title white">Refund Details</h1>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="card">
            <div class="card-content">
              <h4>Reference: {{ refund.reference }}</h4>
              <div class="mb-1">
                <h5>Customer Details</h5>
                <div class="ml-1">
                  <div>
                    <strong>Customer Name: </strong>
                    <span>{{ refund.paidTo?.first_name }} {{ refund.paidTo?.last_name }}</span>
                  </div>
                  <div>
                    <strong>Customer Contact:</strong>
                    <span :class="refund.paidTo?.phone_number ? '' : 'text-danger'">
                  {{ refund.paidTo?.phone_number ? refund.paidTo?.phone_number : 'Not Provided' }}
                  </span>
                  </div>
                  <div>
                    <strong>Customer Email Address: </strong>
                    <span :class="refund.paidTo?.phone_number ? '' : 'text-danger'">
                    {{ refund.paidTo?.email ? refund.paidTo?.email : 'Not Provided' }}
                  </span>
                  </div>
                </div>
              </div>
              <div class="mb-1">
                <h5>Payment Method</h5>
                <div class="ml-1">
                  <div>
                    <strong>Payment Method Name: </strong>
                    <span>{{ refund.payment_method }}</span>
                  </div>
                  <div v-if="refund.payment_gateway">
                    <strong>Payment Gateway Name: </strong>
                    <span>{{ refund.payment_gateway }}</span>
                  </div>
                  <div v-if="refund.payment_gateway_id">
                    <strong>Payment Gateway ID: </strong>
                    <span>{{ refund.payment_gateway_id }}</span>
                  </div>
                </div>
              </div>
              <p><strong>Refund Reason:</strong> {{ refund.reason }}</p>
              <p><strong>Refund Amount:</strong> ${{ ((refund.amount * 1) / 100).toFixed(2) }}</p>
              <p><strong>Refund Status:</strong> <span class="badge"
                                                       :class="refund.status == 'Refunded' ? 'badge-success' : 'badge-warning'">
                {{refund.status }}
              </span></p>
              <p><strong>Record Created At:</strong> {{ refund.created_at }}</p>
              <p><strong>Record Last Updated At:</strong> {{ refund.updated_at }}</p>
              <div>
                <h5>Refund Notes</h5>
                <div v-if="refund.refundNotes">
                  <p class="ml-1 mb-1 border p-1" v-for="(note, index) in refund.refundNotes" :key="index">
                    <strong>Note No. {{ index + 1 }}:</strong><br>
                    <strong>Creator:</strong><span>{{ note.user_type }}</span>
                    <span>&nbsp;({{ note.user?.first_name }} {{ note.user?.last_name }})</span><br>
                    <strong>Note for:</strong> <span>{{ note.type }}</span><br>
                    <strong>Description:</strong> <span>{{ note.description }}</span><br>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "RefundViewModal",
  props: ['refund'],
}
</script>