<template>
<AppLayout>
    <template v-slot:appContent>
        <section class="users-list-wrapper">
            <div class="d-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
                <h4 class="">Refunds/List</h4>
                <div>
                    <router-link :to="can('refund-create') ? {name:'appRefundCreate'} : '#'">
                        <span class="glow d-flex align-items-center" :title="can('refund-create') ? 'Create' : 'Create Forbidden' ">
                            <i class='bx bx-plus bx-flip-vertical'></i>&nbsp;Create
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="users-list-filter px-1 my-2 py-2 border rounded">
                <div>
                    <div class="row align-items-center ">
                        <div class="col-12 col-sm-12 col-md-2">
                            <label>Show Per Page</label>
                            <VueMultiselect v-model="selectedPagination" class="" :options="paginationOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-2">
                            <label>Payment Method</label>
                            <VueMultiselect v-model="selectedPaymentMethod" class="" :options="paymentMethodOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-2">
                            <label>Reason</label>
                            <VueMultiselect v-model="selectedReason" class="" :options="reasonOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-2">
                            <label>status</label>
                            <VueMultiselect v-model="selectedStatus" class="" :options="statusOptions" :close-on-select="true" label="name" track-by="name" :show-labels="false" :allow-empty="false" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 mb-1 mb-lg-0">
                            <label for="amountFrom">Amount From</label>
                            <input v-model="getRefundsParams.amount[0]" class="form-control search-product-input-element" id="amountFrom" type="text" placeholder="Enter Amount From" />
                        </div>
                        <div class="col-12 col-sm-12 col-md-2 mb-1 mb-lg-0">
                            <label>Amount To</label>
                            <input v-model="getRefundsParams.amount[1]" class="form-control search-product-input-element" type="text" placeholder="Enter Amount To" />
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-12 col-sm-12 col-md-6">
                            <label for="whereHasPaidToQuery">Search Customer (Paid To)</label>
                            <input v-model="getRefundsParams.where_has_paid_to_query" id="whereHasPaidToQuery" class="form-control search-product-input-element" type="text" placeholder="Search a customer by name or email, phone number, additional infos, post code or business name">
                        </div>
                        <div class="col-12 col-sm-12 col-md-3">
                            <label for="whereHasPaidToQuery">Reference</label>
                            <input v-model="getRefundsParams.reference" id="whereHasPaidToQuery" class="form-control search-product-input-element" type="text" placeholder="Enter Appointment or Order Reference">
                        </div>

                        <div class="col-12 col-sm-12 col-md-3">
                            <label></label>
                            <button type="reset" class="btn btn-primary btn-block glow users-list-clear mb-0" @click="applyRefundFilter(null)">Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="users-list-table">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table id="users-list-datatable" class="table">
                                <thead>
                                    <tr class="text-capitalize">
                                        <th class="position-relative" :style="{width:'5%'}">SL</th>
                                        <th class="position-relative" :style="{width:'15%'}">Customer</th>
                                        <th class="position-relative" :style="{width:'15%'}">Processed By</th>
                                        <th class="position-relative" :style="{width:'10%'}">Reference</th>
                                        <th class="position-relative" :style="{width:'15%'}">Payment Method</th>
                                        <th class="position-relative" :style="{width:'15%'}">Reasons</th>
                                        <th class="position-relative" :style="{width:'10%'}">Amount</th>
                                        <th class="position-relative" :style="{width:'10%'}">Status</th>
                                        <th :style="{width:'5%'}">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(refund, index) in refunds" :key="index">
                                        <td>{{ index+1 }}</td>
                                        <td>
                                            <router-link v-if="refund.paidTo" :to="can('customer-view') && refund.paidTo?.user?.customer?.id ? {name:'appCustomerView',params: {id:refund.paidTo?.user?.customer?.id}} : '#'">
                                                <span class="customer-name text-capitalize" :title="can('refund-create') ? 'View' : 'View Forbidden' ">
                                                    {{refund.paidTo.first_name }} {{ refund.paidTo.last_name }}
                                                </span>
                                            </router-link>
                                        </td>
                                        <td>
                                            <span class="text-capitalize" v-if="refund.processedBy">
                                                {{refund.processedBy.first_name }} {{ refund.processedBy.last_name }}
                                            </span>
                                        </td>

                                        <td>{{ refund.reference }}</td>
                                        <td>
                                            <div>Method: <span>{{ refund.payment_method }}</span></div>
                                            <div v-if="refund.payment_gateway">Gateway Name: <span>{{ refund.payment_gateway }}</span></div>
                                            <div v-if="refund.payment_gateway_id">Gateway ID: <span>{{ refund.payment_gateway_id }}</span></div>
                                        </td>
                                        <td>{{ refund.reason }}</td>
                                        <td>${{ ((refund.amount * 1)/100).toFixed(2) }}</td>
                                        <td>
                                            <span class="badge" :class="refund.status === 'Refunded' ? 'badge-light-success' : 'badge-light-warning'">
                                                {{ refund.status }}
                                            </span>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-top justify-content-between">
                                                <button :title="can('refund-view') ? 'View' : 'View Unauthenticated'" :disabled="!can('refund-view')" @click="this.selectedRefund = refund" class="btn text-decoration-none cursor-pointer view-edit-delete-icon px-0 py-0" data-toggle="modal" data-target="#refundViewModal">
                                                    <i class="bx bx-show"></i>
                                                </button>
                                                <div class="dropdown py-0 px-0 view-edit-delete-icon" :style="{height:'18px'}">
                                                    <span type="button" class="px-0 py-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                        <i class='bx bx-dots-vertical-rounded h-100'></i>
                                                    </span>
                                                    <div class="dropdown-menu">
                                                        <button class="dropdown-item" :disabled="!can('refund-update')" :title="can('refund-update') ? 'Update' : 'Update Forbidden'" @click="openRefundStatusModal(refund)" >Change Status
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                             <div class="" data-toggle="modal" data-target="#refundStatus"></div>
                             <ListPagination position="right" :pagination-data="paginateLinks" @getClickedPage="applyRefundFilter" />
                            <RefundStatusChangeModal :selectedRefund="selectedRefund" :refund-status="selectedRefund.status" :refund-id="selectedRefund.id" modal-name="refundStatus"  @getRefundList="getRefundList"  />
                        </div>
                        <RefundViewModal :refund="selectedRefund" />
                    </div>
                </div>
            </div>
        </section>
    </template>
</AppLayout>
</template>

<script>
//component
import AppLayout from '@/layouts/backEnd/AppLayout';
import ListPagination from '@/components/backEnd/pagination/ListPagination';
import RefundViewModal from "@/views/backEnd/refunds/includes/RefundViewModal";
import RefundStatusChangeModal from "@/views/backEnd/refunds/includes/RefundStatusChangeModal";

//mixin
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import Loader from "@/components/backEnd/mixins/Loader";
import Authorization from "@/components/backEnd/mixins/Authorization";
//core package
import {
    mapActions,
    mapGetters
} from "vuex";
// package
import VueMultiselect from 'vue-multiselect';

export default {
    name: "RefundList",
    mixins: [ShowToastMessage, Loader, Authorization],
    components: {
        AppLayout,
        ListPagination,
        VueMultiselect,
        RefundViewModal,
        RefundStatusChangeModal
    },
    data() {
        return {
            unauthorized: 'You are unauthorized to perform this action.',

            getSettingsParams: {
                type: ['default'],
                key: ['default_pagination']
            },

            getRefundsParams: {
                reference: '',
                payment_method: '',
                payment_gateway_id: '',
                reason: '',
                amount: [],
                status: '',
                where_has_paid_to_query: '',
                with_relation: ['processedBy', 'paidTo.customer', 'refundNotes.user','appointmentRefundsByReference','orderRefundsByReference'],
                order_by_id: 'DESC',
                paginate: 1,
                pagination: '',
                page: ''
            },

            selectedRefund: {},

            selectedPagination: {
                value: '',
                name: 'Default'
            },
            selectedPaymentMethod: {
                value: '',
                name: 'Any'
            },
            selectedReason: {
                value: '',
                name: 'Any'
            },
            selectedStatus: {
                value: '',
                name: 'Any'
            },
        }

    },
    computed: {
        ...mapGetters({
            refunds: 'appRefunds/refunds',
            paginateLinks: 'appRefunds/paginateLinks',
        }),

        paginationOptions() {
            return [{
                value: this.$store.getters['appSettings/settingDefaultPagination'].value,
                name: 'Default'
            }, {
                value: 25,
                name: '25 Entries'
            }, {
                value: 50,
                name: '50 Entries'
            }, {
                value: 100,
                name: '100 Entries'
            }];
        },
        paymentMethodOptions() {
            return [{
                    value: '',
                    name: 'Any'
                },
                {
                    value: 0,
                    name: 'Cash'
                },
                {
                    value: 0,
                    name: 'Payment Gateway'
                },
            ];
        },
        reasonOptions() {
            return [{
                    value: '',
                    name: 'Any'
                },
                {
                    value: 0,
                    name: 'Incorrect Amount Charged'
                },
                {
                    value: 1,
                    name: 'Duplicate Charge'
                },
                {
                    value: 2,
                    name: 'Tech Poor Service'
                },
                {
                    value: 3,
                    name: 'No Service Provided'
                },
                {
                    value: 4,
                    name: 'Agent Error'
                },
                {
                    value: 5,
                    name: 'Cancelled Appointment'
                },
                {
                    value: 6,
                    name: 'Cancelled Product Order'
                },
                {
                    value: 7,
                    name: 'Cancelled Repair Service'
                },
                {
                    value: 8,
                    name: 'Other'
                }
            ];
        },
        statusOptions() {
            return [{
                    value: '',
                    name: 'Any'
                },
                {
                    value: 0,
                    name: 'Pending'
                },
                {
                    value: 1,
                    name: 'Processing'
                },
                {
                    value: 2,
                    name: 'Refunded'
                },
                {
                    value: 3,
                    name: 'Declined'
                },
                {
                    value: 4,
                    name: 'Cancelled'
                },
            ];
        },
    },
    watch: {
        selectedPagination: function (selectedPagination) {
            this.getRefundsParams.pagination = selectedPagination.value;
        },
        selectedPaymentMethod: function (selectedPaymentMethod) {
            this.getRefundsParams.payment_method = selectedPaymentMethod.value;
        },
        selectedReason: function (selectedReason) {
            this.getRefundsParams.reason = selectedReason.value;
        },
        selectedStatus: function (selectedStatus) {
            this.getRefundsParams.status = selectedStatus.value;
        },
    },

    methods: {
        ...mapActions({
            getSettings: 'appSettings/getSettings',
            getRefunds: 'appRefunds/getRefunds',
            getRefund: 'appRefunds/getRefund',
            deleteRefundOnList: 'appRefunds/deleteRefundOnList',
        }),
        openRefundStatusModal (refund)
        {
            this.selectedRefund = {};
            this.selectedRefund = refund;
            document.querySelector('[data-target="#refundStatus"]').click();
        },
        async getSettingList() {
            await this.getSettings(this.getSettingsParams);
        },

        async getRefundList() {
            await this.getRefunds(this.getRefundsParams)
                .then((response) => {
                    if (response && response.message && response.status !== 200) {
                        this.showToastMessage(response);
                    }
                });
        },

        async applyRefundFilter(pageNumber) {
            await this.loader(true);
            this.getRefundsParams.page = pageNumber;
            await this.getRefundList(this.getRefundsParams);
            await this.loader(false);
        },

        async setSelectedRefund(refund) {
            this.selectedRefund.id = refund.id;
            this.selectedRefund.status = refund.status;

        }
    },
    async mounted() {
        await this.loader(true);
        await this.getSettingList();
        await this.getRefundList();
        await this.loader(false);
    }

}
</script>

<style scoped>
.customer-name {
    color: #5A8DEE;
}

.search-product-input-element {
    min-height: 41px !important;
    padding: 8px 40px 8px 8px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    background: #fff;
    font-size: 14px;
}
</style>
